import React, { useContext } from "react";
import styled from "styled-components";
import Menu from "react-burger-menu/lib/menus/slide";
import Media from "react-media";

import { Link } from "gatsby";
import { AuthContext } from "./AuthContext";
import Button from "./Button";

const NavBar = styled.nav`
  border-bottom: 1px solid #999;
  padding: 12px 25px;
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
  a {
    text-transform: none;
    text-decoration: none;
    color: #111;
  }
  ul {
    padding: 0;
    list-style: none;
    display: grid;
    grid-auto-flow: column;
    grid-gap: 20px;
    @media (max-width: 700px) {
      grid-auto-flow: row;
    }
    li {
      padding: 20px;
      box-sizing: border-box;
      border-bottom: 1px solid greenyellow;
      @media (max-width: 700px) {
        border-bottom: none;
        border-left: 4px solid greenyellow;
      }
      a {
        &:hover {
          text-decoration: underline;
        }
        text-transform: uppercase;
        letter-spacing: 0.3px;
      }
    }
  }
  /* Position and sizing of burger button */
  .bm-burger-button {
    position: relative;
    width: 36px;
    height: 30px;
    right: 20px;
    top: 0;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #666;
  }

  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: limegreen;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 44px;
    width: 44px;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: grey;
  }

  /*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
    margin-top: -20px;
  }

  /* General sidebar styles */
  .bm-menu {
    background: white;
    padding: 2.5em 1.5em;
    border-left: 1px solid rgba(0, 0, 0, 0.2);
  }
  .bm-item-list {
    display: grid;
    grid-template-rows: auto 125px;
  }
  .bm-item {
    display: grid;
  }
`;

const LogoFont = styled.span`
  font-family: "Bangers";
  font-size: 2rem;
  padding: 0.5rem 2rem;
  display: grid;
  place-items: center;
  width: 50px;
  text-shadow: -2px -2px yellow, 2px 2px orange, 4px 4px orange;
  background-size: 8px 8px, 8px 8px, 8px 8px, 8px 8px, 8px 8px, 100% 100%;
  text-transform: none;
  background-image: radial-gradient(
      ellipse farthest-corner,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 35%,
      #ffffff 30%,
      #ffffff 40%,
      rgba(0, 0, 0, 0) 90%
    ),
    radial-gradient(
      ellipse farthest-corner at 0px 0px,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 20%,
      #ffffff 15%,
      #ffffff 20%,
      rgba(0, 0, 0, 0) 50%
    ),
    radial-gradient(
      ellipse farthest-corner at 8px 8px,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 20%,
      #ffffff 15%,
      #ffffff 20%,
      rgba(0, 0, 0, 0) 50%
    ),
    radial-gradient(
      ellipse farthest-corner at 0px 8px,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 20%,
      #ffffff 15%,
      #ffffff 20%,
      rgba(0, 0, 0, 0) 40%
    ),
    radial-gradient(
      ellipse farthest-corner at 8px 0px,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 20%,
      #ffffff 15%,
      #ffffff 20%,
      rgba(0, 0, 0, 0) 50%
    ),
    linear-gradient(
      40deg,
      #bd2d10 0,
      #f4502f 30%,
      #ff6e51 50%,
      #f4502f 70%,
      #bd2d10 100%
    );
`;
const Avatar = styled.div<{ url?: string }>`
  height: 40px;
  width: 40px;
  border-radius: 25px;
  background-image: ${({ url }) =>
    url ? `url(${url})` : `url(images/profileIcon.svg)`};
  background-size: cover;
`;

const UserInfo = styled.div`
  display: grid;
  grid-auto-flow: column;
  @media (max-width: 700px) {
    grid-auto-flow: row;
    grid-gap: 20px;
  }
  align-items: center;
  grid-gap: 10px;
`;

const NavMenu = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 40px;
  justify-content: center;
  align-items: center;
`;

const DesktopMenu = styled.div`
  display: grid;
`;

export const Navigation = () => {
  const { logout, auth } = useContext(AuthContext);
  const Nav = (
    <NavMenu>
      <ul>
        <li>
          <Link to="/my-polls">My Polls</Link>
        </li>
        <li>
          <Link to="/builder">Poll Builder</Link>
        </li>
      </ul>
    </NavMenu>
  );
  return (
    <NavBar>
      <Link to="/">
        <LogoFont>TvT</LogoFont>
      </Link>
      <Media queries={{ small: "(max-width: 700px)" }}>
        {(matches) =>
          matches.small ? (
            <Menu right noOverlay disableAutoFocus>
              {Nav}
              {auth.isUserSignedIn && (
                <div style={{ display: `grid`, alignItems: "center" }}>
                  <Avatar url={auth.avatarUrl} />
                  <span>Hi, {auth.username}</span>
                  <Button type={"tertiary"} onClick={() => logout()}>
                    Log out
                  </Button>
                </div>
              )}
            </Menu>
          ) : (
            <>
              {Nav}
              {auth.isUserSignedIn && (
                <UserInfo>
                  <span>Hi, {auth.username}</span>
                  <Avatar url={auth.avatarUrl} />
                  <Button type={"tertiary"} onClick={() => logout()}>
                    Log out
                  </Button>
                </UserInfo>
              )}
            </>
          )
        }
      </Media>
    </NavBar>
  );
};
