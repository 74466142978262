import React from "react";
import { Navigation } from "../components/Navigation";
import "./index.css";
import styled from "styled-components";
import { AuthContextProvider } from "../components/AuthContext";
interface LayoutProps {
  children: any;
}

const MainContent = styled.main`
  padding: 25px;
`;

export const Layout = ({ children }: LayoutProps) => {
  return (
    <AuthContextProvider>
      <Navigation />
      <MainContent>{children}</MainContent>
    </AuthContextProvider>
  );
};
