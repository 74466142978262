import React from "react";
import styled from "styled-components";

const PrimaryButton = styled.button`
  cursor: pointer;
  text-align: center;
  max-width: 400px;
  padding: 25px;
  text-decoration: none;
  color: #222;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 700;
  &:hover {
    opacity: 0.75;
  }
  background-color: greenyellow;
  border: 2px solid transparent;
`;

const SecondaryButton = styled.button`
  cursor: pointer;
  text-align: center;
  max-width: 400px;
  padding: 25px;
  text-decoration: none;
  color: #222;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 700;
  &:hover {
    opacity: 0.75;
  }
  border: 2px solid greenyellow;
`;

const TertiaryButton = styled.button`
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  max-width: 400px;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 700;
  & :hover {
    opacity: 0.75;
  }
  border: 1px solid #999;
  color: #333;
  padding: 8px 12px;
  box-sizing: border-box;
`;

const ButtonElements = {
  primary: PrimaryButton,
  secondary: SecondaryButton,
  tertiary: TertiaryButton,
};

type ButtonType = "primary" | "secondary" | "tertiary";

interface Props {
  children: any;
  type: ButtonType;
  disabled?: boolean;
  onClick: () => void;
}

const Button = ({ type, children, disabled = false, onClick }: Props) => {
  const ButtonComponent = ButtonElements[type];
  return (
    <ButtonComponent disabled={disabled} onClick={onClick}>
      {children}
    </ButtonComponent>
  );
};

export default Button;
